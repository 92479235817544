<template>
  <div 
    class="Logoname"
    @click="goHome()">
    Datavizium
  </div>
</template>

<script>
export default {
  name: 'Logoname',
  methods: {
    goHome() {
      this.$router.push({
        name:'Home'
      })
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
.Logoname {
  font-family: 'Playfair Display', serif;
  font-size: 2em;
  cursor: pointer;
}
</style>
