import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const moduleDrawer = {
  namespaced: true,
  state: () => ({
    left:true,
    open:true
  }),
  mutations: {
    SET_DRAWER(
      state,
      drawer
    ) {
      state
        .open = drawer
    },
  },
  actions: {
    setDrawer(
      context,
      drawer
    ) {
      console.log(
        "opening drawer"
      )
      context
        .commit(
          'SET_DRAWER',
          drawer
        )
    },
  },
  getters: {

  }
}

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    drawer: moduleDrawer
  },
  namespaced: true
})
