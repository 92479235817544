<template>
  <v-app>

    <v-app-bar app color="primary" dark dense>
      <v-btn icon @click="$store.state.drawer.left = !$store.state.drawer.left">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <Logoname />
      </div>
      <v-spacer></v-spacer>
      <v-btn icon @click="$store.state.drawer.open = !$store.state.drawer.open">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Logoname from './components/Logoname';


import {
  mapActions
} from 'vuex'


export default {
  name: 'App',

  components: {
    // HelloWorld,
    Logoname,

  },

  methods: {
    openDrawer() {
      this.setDrawer(
        true
      )
    },
    ...mapActions(
      'drawer',
      [
        'setDrawer'
      ]
    )
  },

  data: () => ({
    //
  }),
};
</script>
