import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: loadView("Home"),
  },
  {
    path: "/editor",
    name: "Editor",
    component: loadView("Editor"),
  },
  {
    path: "/about",
    name: "About",
    component: loadView("About"),
  },
  {
    path: "/borders",
    name: "Borders",
    component: loadView("Borders"),
  },
  {
    path: "/oceans",
    name: "Oceans",
    component: loadView("Oceans"),
  },
  {
    path: "/presse",
    name: "Presse",
    component: loadView("Presse"),
  },
  {
    path: "/commodities",
    name: "Commodities",
    component: loadView("Commodities"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
